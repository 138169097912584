<template>
    <v-container>
        <Header
            :registryStatus="getEnforcementsStatuses"
            :recordIndex="getEnforcementsDetails.index"
            :registryTitle="getEnforcementsRegistry.name"
            :registryUrl="'enforcements'"
            :actualStatus="getEnforcementsDetails.status"
            :avatarData="getEnforcementsDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('enforcements:editionEnforcement')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editEnforcement()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('enforcements:editEnforcements') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '../../../components/Registries/Enforcements/Details/Information.vue'
import AEContent from './../../../components/Registries/Enforcements/Modal/Content'
import Buttons from './../../../components/Registries/Enforcements/Modal/Buttons'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters([
            'getEnforcementsStatuses',
            'getEnforcementsRegistry',
            'getEnforcementsDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setEnforcementsDetails',
            'setEnforcementsStatuses',
            'setEnforcementsRegistry',
            'setEnforcementsModal',
            'clearEnforcementsErrors',
            'clearEnforcementsInput'
        ]),
        ...mapActions(['fetchEnforcement', 'updateEnforcementsStatus']),
        changeStatus(status) {
            this.updateEnforcementsStatus({ status, id: this.getEnforcementsDetails._id })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editEnforcement() {
            this.open = true
            this.clearEnforcementsErrors()
            this.setEnforcementsModal()
        },
        closeModal() {
            this.open = false
            this.clearEnforcementsErrors()
            this.clearRepresentativesInput()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchEnforcement', { id: to.params.id, next })
    },
}
</script>
