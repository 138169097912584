<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{ getEnforcementsDetails.contractor.name }}
                        </p>
                        <span
                            ><b>{{ `${$t('enforcements:title')}` }}:</b>
                            {{ $get(getEnforcementsDetails, 'title', '') }}
                        </span>
                        <span
                            ><b>{{ `${$t('enforcements:insertionDate')}` }}:</b>
                            {{
                                getEnforcementsDetails.createdAt
                                    ? $moment(
                                          getEnforcementsDetails.createdAt
                                      ).format(`DD/MM/YYYY`)
                                    : $t('global:empty')
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="$get(getEnforcementsDetails, 'title', '')"
                            :label="$t('enforcements:title')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(
                                    getEnforcementsDetails,
                                    'contractor.name',
                                    ''
                                )
                            "
                            :label="$t('enforcements:contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                findRepresentative(getEnforcementsDetails)
                            "
                            :label="$t('enforcements:representative')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            rows="1"
                            auto-grow
                            no-resize
                            readonly
                            :value="
                                $get(getEnforcementsDetails, 'description', '')
                            "
                            :label="$t('enforcements:description')"
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getEnforcementsDetails']),
    },
    methods: {
        findRepresentative(item) {
            //add a period after each name
            return `${this.$get(
                    item.representative,
                    'name',
                    'Brak'
                )} ${this.$get(item.representative, 'lastname', '')} ${
                    item.representative && item.representative.email ? '•' : ''
                } ${this.$get(item.representative, `email`, '')} ${
                    item.representative && item.representative.phoneNumber ? '•' : ''
                } ${this.$get(item.representative, 'phoneNumber', '')}`
        }
    }
}
</script>
